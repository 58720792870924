<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h2 class="card-title">Bestellte Artikel</h2>
            <div class="card-tools">
              <!-- <button class="btn btn-danger  mr-1"><i class="fas fa-minus"></i> Alle markierten löschen</button> -->
              <!-- <button class="btn btn-success mr-1" @click="addDepositReturn"><i class="fas fa-plus"></i> Pfandrückgabe</button>
              <button class="btn btn-secondary mr-1" @click="addPosition"><i class="fas fa-plus"></i> Freie Position</button>
              <button class="btn btn-primary" @click="addArticleModal"><i class="fas fa-plus"></i> Artikel</button> -->
              <!-- <button type="button" class="btn btn-warning" @click="handleModal"><i class="fas fa-minus"></i> Stornieren</button> -->
              
              <router-link :to="{name: 'orders.create', params: {id: order.customer.id}}" class="btn btn-success mr-1"><i class="fas fa-fw fa-plus"></i> Nachbestellung</router-link>
              <template v-if="order.useInvoice == 1">
                <button type="button" class="mr-1 btn btn-warning" @click="handleModal"><i class="fas fa-fw fa-minus"></i> Stornieren</button>
                <button type="button" class="btn btn-danger" v-if="order.stornos.length == 0" @click="handleFullStorno"><i class="fas fa-fw fa-trash-alt"></i> Vollstorno</button>
              </template>
            </div>
          </div>

          <div class="card-body">
            <table class="table table-striped table-sm table-bordered">
              <thead class="thead-light">
                <th><input type="checkbox" v-model="selectAll"/></th>
                <th>Artikelnummer</th>
                <th>Artikelname</th>
                <th>Anzahl</th>
                <th>Storniert</th>
                <th>Preis</th>
                <th>Gesamt</th>
                <th>Steuern</th>
                <th>Aktion</th>
              </thead>

              <tbody>
                <tr v-for="(item) in order.items" :key="item.id" >
                  <td><input type="checkbox" v-model="selectedItems" :value="item.id" number /></td>
                  <td> 
                    <span>{{ item.articleordernumber }}</span>
                  </td>
                  <td>
                    
                    <span>
                      {{ item.name }}
                      <template v-if="item.option == 'print'"> - PDF-Versand</template>
                      <template v-if="item.option == 'post'"> - Post-Versand</template>
                      <div class="m-2 ml-4" v-if="item.bundle.length >= 1">
                        Inhalt:
                        <table class="table table-striped table-sm table-bordered">
                          <tbody>
                            <tr v-for="bun in item.bundle" :key="bun.id">
                              <td>
                                {{ bun.ordernumber }}
                              </td>
                              <td>
                                {{ bun.name }}
                              </td>
                              <td>
                                {{ bun.quantity }} Stk. 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </span>
                  </td>

                  <td>
                  
                    <span>{{ item.quantity }}</span>
                  </td>

                  <td>
                    {{  item.canceled }}
                  </td>

                  <td>
                
                    <span>{{ item.price | toCurrency }}</span>
                  </td>
                  <td>
                    <span>{{ item.quantity * item.price | toCurrency }}</span>
                  </td>
                  <td>
                  
                    <span>{{ item.tax_rate }} %</span>
                  </td>

                  <td>
                    <b-button v-if="item.comment != null" :id="'popover-button-comment-' + item.id" variant="primary" size="xs">Grußtext</b-button>
                    <b-popover :target="'popover-button-comment-' + item.id" title="Grußtext">
                      {{ item.comment }}
                    </b-popover>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-6">
        <table class="table table-striped">
          <thead>
            <th>Artikelnummer</th>
            <th>Artikelname</th>
            <th>Anzahl</th>
            <th>Stornieren</th>
            <th>Steuern</th>
            <th>Preis</th>
            <th>Gesamt</th>
          </thead>
          <tbody>
            <tr v-for="(storno, index) in stornos" :key="storno.id">
              <td>{{ storno.articleordernumber }}</td>
              <td>{{ storno.name }}</td>
              <td>{{ storno.quantity }}</td>
              <td>
                <select class="form-control form-control-sm" :disable="(storno.quantity - storno.canceled) >= 0" v-model="stornos[index].qty_sto">
                  <option value="0">0</option>
                  <option v-for="n in (storno.quantity - storno.canceled)" :value="n" :key="n">{{ n }} </option>
                </select>
              
              <td>{{ storno.tax_rate }} %</td>
              <td>{{ storno.price | toCurrency }}</td>
              <td>-{{ storno.qty_sto }}</td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>

    <!-- <div class="row">
        <div class="col-md-12">
            <button class="btn btn-primary float-right" @click="saveOrder">Speichern</button>
        </div>
    </div> -->

    <!-- <b-modal id="articleModal" title="Artikel hinzufügen" ok-only ok-variant="danger" ok-title="Speichern" size="xl">
      <div class="row">
        <div class="col-md-12">
          <div class="input-group input-group-sm">
              <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getAllArticles" v-model="search" />
              <button type="submit" class="btn btn-default" @click.prevent="getAllArticles">
                  <i class="fas fa-search"></i>
              </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped">
              <thead>
                  <tr data-widget="expandable-table" aria-expanded="false">
                      <th>
                          <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                          <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'ordernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                      </th>
                      <th>
                          <a href="#" @click.prevent="changeSort('name')">Artikelname</a>
                          <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                      </th>
                      <th><a href="#" @click.prevent="changeSort('producer')">Hersteller</a>
                          <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'producer' && this.params.sort_direction == 'desc'" >&darr;</span></th>
                      <th>
                          <a href="#" @click.prevent="changeSort('price')">Preis</a>
                          <span v-if="this.params.sort_field == 'price' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'price' && this.params.sort_direction == 'desc'" >&darr;</span>
                      </th>
                      <th>
                          <a href="#" @click.prevent="changeSort('tax')">Steuersatz</a>
                          <span v-if="this.params.sort_field == 'tax' && this.params.sort_direction == 'asc'" >&uarr;</span>
                          <span v-if="this.params.sort_field == 'tax' && this.params.sort_direction == 'desc'" >&darr;</span>
                      </th>
                      <th style="width:15%; text-align:center;">Aktionen</th>
                  </tr>
                  <tr class="expandable-body">
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>
                          <select v-model="params.producer_id" class="form-control form-control-sm">
                              <option :value="''">-- Filter Hersteller --</option>
                              <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.name }}</option>
                          </select>
                      </th>
                      <th>&nbsp;</th>
                      <th>
                          <select v-model="params.tax_id" class="form-control form-control-sm">
                              <option :value="''">-- Filter Steuer --</option>
                              <option v-for="tax in taxes" :key="tax.id" :value="tax.id">{{ tax.description }}</option>
                          </select></th>
                      <th>&nbsp;</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(article,index) in articles.data" :key="article.id">
                      <td>{{ article.ordernumber }}</td>
                      <td>{{ article.name }}</td>
                      <td>{{ article.producer }}</td>
                      <td>{{ article.price | toCurrency}}</td>
                      <td>{{ article.tax }}</td>
                      <td style="text-align:center;">
                          
                          <b-button size="sm" @click="addArticle(index)" variant="primary"><i class="fas fa-plus"></i>Hinzufügen</b-button>
                      </td>
                  </tr>
              </tbody>
          </table>
          <div class="clearfix align-middle card-footer">
              <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
              
              <select class="float-right form-control-sm" v-model="params.per_page">
                  <option value="25">25 Artikel</option>
                  <option value="50">50 Artikel</option>
                  <option value="75">75 Artikel</option>
                  <option value="100">100 Artikel</option>
                  <option value="125">125 Artikel</option>
                  <option value="150">150 Artikel</option>
              </select>
              <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
          </div>
        </div>
      </div>
    </b-modal> -->

    <b-modal id="stornoModal" title="Positionen stornieren" scrollable ok-only ok-variant="danger" ok-title="Rechnungskorrektur erstellen" size="xl" @ok="doCorrection">
      <div class="row">
        <div class="col-md-9">
          <div class="card card-primary card-outline">
            <div class="card-header">
              <h3 class="card-title">Bestellte Artikel</h3>
              <div class="card-tools">
                <div class="form-group mr-2" style="display:inline-block;">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="stornoShipping" :disabled="order.invoice_shipping <= 0 || order.shipping_refunded == 1">
                    <label class="custom-control-label" for="customSwitch1">Versandkosten stornieren(-{{ order.invoice_shipping | toCurrency }})</label>
                  </div>
                </div>
                <button class="btn btn-success btn-sm mr-1" @click="addAdditionalDeposit"><i class="fas fa-plus"></i> Pfandrückgabe</button> 
                <button class="btn btn-primary btn-sm" @click="addAdditionalStorno"><i class="fas fa-plus"></i> Zusatzposition</button> 
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-md-2">
                  <span>Discount: {{ parseFloat(order.discount) | toCurrency }}</span>
                </div>
                <div class="col-md-2">
                  <span>Discountable: {{ getDiscountableTotal() | toCurrency }}</span>
                </div>
                <div class="col-md-2">
                  <span>Prozent: {{ getDiscountPercent() / 100 | toPercent }}</span>
                </div>
              </div>
              <table class="table table-striped table-sm table-bordered">
                <thead class="thead-light">
                  <th style="width: 10%;">Artikelnummer</th>
                  <th>Artikelname</th>
                  <th style="width: 10%;">Anzahl</th>
                  <th style="width: 10%;">Stornieren</th>
                  <th style="width: 10%;">Steuern</th>
                  <th style="width: 10%;">Preis <span v-if="order.tax == 0">(Netto)</span></th>
                  <th style="width: 10%;">Gesamt <span v-if="order.tax == 0">(Netto)</span></th>
                </thead>
                <tbody>
                  <tr v-for="(storno, index) in stornos" :key="storno.id" :class="checkDisableState(storno) == true ? 'table-danger' : ''">
                    <td>
                        <template>{{ storno.articleordernumber }}</template>
                    </td>
                    <td>
                      <template>{{ storno.name }}</template>
                    </td>
                    <td>
                      <template>{{ storno.quantity }}</template>
                    </td>
                    <td>
                      <select class="form-control form-control-sm" :disabled="checkDisableState(storno)" v-model="stornos[index].qty_sto">
                        <option value="0">0</option>
                        <option v-for="n in (storno.quantity - storno.canceled)" :value="n" :key="n">{{ n }} </option>
                      </select>
                    </td>
                    <td>
                      <template>{{ storno.tax_rate }} %</template>
                    </td>
                    <td>
                        <template v-if="order.tax == 1">
                          <span>{{ storno.price | toCurrency }}</span>
                          <span v-if="getDiscountPerItem(storno) > 0" style="color:red;">(+{{ getDiscountPerItem(storno) | toCurrency }})</span>
                        </template>
                        <template v-if="order.tax == 0">
                          <span>{{ storno.price_netto | toCurrency }}</span>
                          <span v-if="getDiscountPerItem(storno) > 0" style="color:red;">(+{{ getDiscountPerItem(storno) | toCurrency }})</span>
                        </template>
                    </td>
                    <td>
                        <template v-if="order.tax == 1">
                          <span>{{ storno.price * storno.qty_sto | toCurrency }}</span>
                          <span v-if="getDiscountPerItem(storno) * storno.qty_sto > 0" style="color:red;">(+{{ getDiscountPerItem(storno) * storno.qty_sto | toCurrency }})</span>
                        </template>
                        <template v-if="order.tax == 0">
                          <span>{{ storno.price_netto * storno.qty_sto | toCurrency }}</span>
                          <span v-if="getDiscountPerItem(storno) * storno.qty_sto > 0" style="color:red;">(+{{ getDiscountPerItem(storno) * storno.qty_sto | toCurrency }})</span>
                        </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
      
        
        </div>

        <div class="col-md-3">
          <div class="card card-outline card-primary">
            <div class="card-header">
              <h2 class="card-title">Gesamt</h2>
            </div>
            <div class="card-body">
              <strong>Gesamtbetrag (Netto): {{ generateTotalStornoNetto() | toCurrency }}</strong>
              <template v-if="order.tax == 0">
                <div class="row" v-for="tax in calculateTaxesNetto()" :key="tax.tax">
                    <div class="col-md-12">
                        <span><em>zzgl. {{ parseFloat(tax.tax) }} % MwSt: {{ tax.regularTax | toCurrency }}</em></span>
                    </div>
                </div>
              </template>
              <template v-else>
                <div class="row" v-for="tax in calculateTaxesBrutto()" :key="tax.tax">
                    <div class="col-md-12">
                        <span><em>zzgl. {{ parseFloat(tax.tax) }} % MwSt: {{ tax.regularTax | toCurrency }}</em></span>
                    </div>
                </div>
              </template>
              <hr>
              <strong>Gesamtbetrag (Brutto): {{ generateTotalStornoBrutto() | toCurrency }}</strong><br>
              
            </div>
            <div class="card-footer">
              <!-- <button class="btn btn-success btn-xs mr-1" @click="addAdditionalDeposit"><i class="fas fa-plus"></i> Pfandrückgabe</button> 
              <button class="btn btn-primary btn-xs" @click="addAdditionalStorno"><i class="fas fa-plus"></i> Zusatzposition</button>  -->
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="stornos_additional.length > 0">
        <div class="col-md-9">
          <div class="card card-outline card-primary">
            <div class="card-header">
              <h3 class="card-title">Zusatzpositionen</h3>
            </div>

            <div class="card-body">
              <table class="table table-striped table-sm table-bordered">
                <thead class="thead-light">
                  <th style="width: 10%;">Artikelnummer</th>
                  <th>Artikelname</th>
                  <th style="width: 10%;">Anzahl</th>
                  <th style="width: 10%;">Stornieren</th>
                  <th style="width: 10%;">Steuern</th>
                  <th style="width: 10%;">Preis</th>
                  <th style="width: 10%;">Gesamt</th>
                  <th style="width: 5%;">Aktion</th>
                </thead>

                <tbody>
                  <tr v-for="(storno, index) in stornos_additional" :key="storno.id">
                    <td>-</td>
                    <td><input type="text" v-model="stornos_additional[index].name" class="form-control form-control-sm" /></td>
                    <td><input type="number" v-model="stornos_additional[index].quantity" class="form-control form-control-sm" oninput="validity.valid||(value='');" min="0" step="1" /></td>
                    <td>-</td>
                    <td>
                      <select number class="form-control form-control-sm" v-model="stornos_additional[index].tax_rate" v-if="storno.type == 'normal'">
                        <option v-for="tax in taxes" :key="tax.id" :value="tax.tax">{{ tax.description }}</option>
                      </select>
                      <span v-else>{{ storno.tax_rate }}</span>
                    </td>
                    <td><input type="number" v-model="stornos_additional[index].price" class="form-control form-control-sm" min="0" step="0.01" /></td>
                    <td>{{ storno.price * storno.quantity | toCurrency }}</td>
                    <td>
                      <button type="button" class="btn btn-danger btn-xs" @click="removeAdditionalStorno(index)"><i class="fas fa-trash"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        
        <b-button size="sm" variant="danger" @click="cancel()">
          Abbrechen
        </b-button>
        <b-button size="sm" variant="success" @click="doCorrection()" :disabled="allow_storno == false || generateTotalStornoBrutto() >= 0 || generateTotalStornoNetto() >= 0">
          Storno erstellen
        </b-button>
      </template>

    </b-modal>

    <b-modal id="refundStornoModal" title="Wie soll der offene Betrag erstattet werden?" ok-only ok-variant="danger" ok-title="Speichern" size="md" @ok="handleRefundOk">

        <div class="form-group">
            <label for="name" class="control-label">Modus*</label>
            <select v-model="refund.mode" class="form-control form-control-sm">
                <option v-if="order.chargeId != null" value="amazon">Amazon</option>
                <option v-if="order.paypal_order_id != null" value="paypal">PayPal</option>
                <option v-if="order.stripe_pi != null" value="card">Kreditkarte</option>
                <option v-if="order.ingenico_paymentId != null" value="card_ingenico">Kreditkarte</option>
                <!-- <option value="voucher">Gutschein</option> -->
                <option value="bank">Überweisung</option>
            </select>
        </div>


        <div class="form-group">
            <label for="name" class="control-label">Wert*</label>
            <input v-model="refund.amount" type="text" class="form-control form-control-sm">
        </div>

    </b-modal>

  </div>
</template>

<script>
export default {
  name: 'OrderDetailsItems',
  props: [
      'order'
  ],

  data(){
    return {
      refund: {
        amount: '',
        mode: 'bank',
        order_storno_id: '',
      },
      taxes: [],
      selectedRow: {},
      selectedCell: null,
      selectedItems: [],
      producers: {},
      stornoShipping: false,
      articles: {},
      stornos: [],
      comment: "",
      stornos_additional: [],
      storno_total: 0,
      storno_total_netto: 0,
      storno_final: {
        order_id: '',
        comment: '',
        status_id: '',
        invoice_amount: '',
        invoice_amount_net: '',
        items: [],
      },
      allow_storno: false,
      params: {
                sort_field: 'ordernumber',
                sort_direction: 'asc',
                producer_id: '',
                tax_id: '',
                per_page: 25,
                category_id: '',
            },
            search: '',
    }
  },

  watch: {
        params: {
            handler () {
                this.getAllArticles();
            },
            deep: true
        },

        stornos: {
          handler() {
              this.generateTotalStorno();
              this.checkStorno();
          },
          deep: true
        },

        stornos_additional: {
          handler() {
              this.generateTotalStorno();
              this.checkStorno();
          },
          deep: true
        },

        stornoShipping: function() {
          this.generateTotalStorno();
          this.checkStorno();
        },

    },

  computed: {
        selectAll: {
            get: function () {
                return this.order.items ? this.selectedItems.length == this.order.items.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.order.items.forEach(function (item) {
                        selected.push(item.id);
                    });
                }

                this.selectedItems = selected;
            }
        }
    },

  methods:{
    checkDisableState(storno){
      var disable = false;

      if((storno.quantity - storno.canceled) <= 0) disable = true
      if(storno.voucher != null){
        if(storno.voucher.rest_amount < storno.voucher.amount) disable = true
      }

      return disable;
    },

    handleRefundOk(bvModalEvt){
        bvModalEvt.preventDefault()
        this.createRefund()
    },

    createRefund(){
            this.axios
                .post("/refunds", {
                    'order_id': this.order.id,
                    'mode': this.refund.mode,
                    'amount': this.refund.amount,
                    'order_storno_id': this.refund.order_storno_id,
                })
                .then(() => {
                    this.$parent.getOrder();
                    this.$bvModal.hide("refundStornoModal");
                    this.$swal({
                        icon: "success",
                        title: "Erstattung wurde hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },


    checkStorno(){
      for(let i = 0; i < this.stornos.length; i++){
        if(this.stornos[i].qty_sto > 0)
        {
          this.allow_storno = true;
          // break;
          return;
        }
      }

      if(this.stornos_additional.length > 0)
      {
        this.allow_storno = true;
        return;
      }

      if(this.stornoShipping == true)
      {
        this.allow_storno = true;
        return;
      }

      this.allow_storno = false;
      return;
    },


    doCorrection(){
      // console.log('Haha');
      this.storno_final.order_id = this.order.id;
      this.storno_final.comment = this.comment;
      this.storno_final.status_id = 1;
      this.storno_final.tax = this.order.tax;
      this.storno_final.invoice_amount = Number(parseFloat(this.generateTotalStornoBrutto()).toFixed(2));
      this.storno_final.invoice_amount_net = Number(parseFloat(this.generateTotalStornoNetto()).toFixed(2));
      this.storno_final.items = [];

      this.stornos.forEach(function(item){
        if(item.qty_sto > 0)
        {
          var price = 0;
          if(this.order.tax == 0){
            //Netto
            price = Number(parseFloat(((item.price_netto / 100) * (100 + Number(parseFloat(item.tax_rate).toFixed(2))))).toFixed(2))
          }
          else{
            price = item.price;
          }
          this.storno_final.items.push({
            article_id: item.article_id,
            article_detail_id: item.article_detail_id,
            articleordernumber: item.articleordernumber,
            price:price * -1,
            quantity: item.qty_sto,
            name: item.name,
            tax_id: item.tax_id,
            tax_rate: item.tax_rate,
            order_item_id: item.order_item_id,
            voucher_id: item.voucher_id,
          });

          if(item.discountable == 1 && (this.order.discount != null || this.order.discount > 0)){
              // Addiere nun noch den Rabatt, da ja nicht der original Preis storniert werden darf
              var priceWR = 0;
              if(this.order.tax == 1){
                //Brutto
                priceWR = (this.getDiscountPerItem(item))
              }
              else{
                priceWR = Number(parseFloat((((this.getDiscountPerItem(item) / 100) * (100 + Number(parseFloat(item.tax_rate).toFixed(2)))))).toFixed(2));
              }
              this.storno_final.items.push({
                article_id: 0,
                article_detail_id: 0,
                articleordernumber: item.articleordernumber + '.WR',
                price:priceWR,
                quantity: item.qty_sto,
                name: 'Warenkorbrabatt ' + this.getDiscountPercent() + ' %',
                tax_id: item.tax_id,
                tax_rate: item.tax_rate,
                order_item_id: null,
                voucher_id: null,
              });
          }
        }
      },this);

      this.stornos_additional.forEach(function (item){
        var tax = this.taxes.find(taxx => taxx.tax == item.tax_rate);
        var price = 0;
        if(item.type == 'normal')
        {
          //Normaler Artikel = Preis in Brutto und fertig
          if(this.order.tax == 0){
            //Netto
            price = Number(parseFloat(((item.price / 100) * (100 + Number(parseFloat(item.tax_rate).toFixed(2))))).toFixed(2))
          }
          else{
            price = price = item.price;
          }
          
        }

        if(item.type == 'deposit')
        {
          
          //Prüfe nun ob es ein Firmen oder Privatkunde ist
          if(this.order.tax == 1)
          {
            //Kunde ist ein Privatkunde
            price = item.price * -1;
          }

          if(this.order.tax == 0)
          {
            //Kunde ist Firmenkunde. Pfand ist plus Mwst
            price = (((parseFloat(item.price) / 100) * -1) * (100 + parseFloat(item.tax_rate)));
          }
        }

        if(item.quantity < 0)
        {
          price = price * -1;
        }

        this.storno_final.items.push({
            article_id: 0,
            article_detail_id: 0,
            articleordernumber: '',
            price:price,
            quantity: item.quantity,
            name: item.name,
            tax_id: tax.id,
            tax_rate: item.tax_rate,
        });
      },this);

      if(this.stornoShipping == true)
      {
        var tax_shipping = this.taxes.find(taxx => taxx.tax == this.order.invoice_shipping_tax_rate);
        this.storno_final.items.push({
            article_id: 0,
            article_detail_id: 0,
            articleordernumber: '',
            price: this.order.invoice_shipping * -1,
            quantity: 1,
            name: 'Stornierung Versandkosten',
            tax_id: tax_shipping.id,
            tax_rate: this.order.invoice_shipping_tax_rate,
            shipping: true,
        });
      }

      this.axios
        .post("/orders/stornos", this.storno_final)
        .then((response) => {
            this.$swal({
                icon: "success",
                title: "Artikel wurden storniert",
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            });
            // this.$router.push({name: 'orders.index'});
            setTimeout(() => {
                  this.$bvModal.hide("stornoModal");
                  this.$parent.getOrder();
                  this.loading = false;
                  this.refund.order_storno_id = response.data.data.id;
                  this.refund.amount = this.storno_final.invoice_amount.toFixed(2) * -1;
                  this.$bvModal.show("refundStornoModal");
            }, 1000);
        })
        .catch((error) => {
            var message = "Da ist etwas schief gelaufen";
            if(error.response.status == 422)
            {
              message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
            }
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: message,
            });
        });

    },

    removeAdditionalStorno(index){
      this.$delete(this.stornos_additional, index);
    },

    addAdditionalStorno(){
      this.stornos_additional.push({
        id: Math.floor(Math.random() * 101),
        name: 'Freie Position',
        quantity: 1,
        tax_rate: '19.00',
        price: 1,
        price_netto: 1,
        type: 'normal',
        article_id: 0,
        article_detail_id: 0,
        articleordernumber: 'FP',
        discountable: 0,
      });
    },

    addAdditionalDeposit(){
      this.stornos_additional.push({
        id: Math.floor(Math.random() * 101),
        name: 'Pfandrückgabe',
        quantity: 1,
        tax_rate: '19.00',
        price: 1,
        price_netto: 1,
        type: 'deposit',
        article_id: 0,
        article_detail_id: 0,
        articleordernumber: 'PR',
        discountable: 0,
      });
    },

    calculateTaxesNetto(){
        var groupedByTax = [];

        this.stornos.forEach(function(item) {
            var tax = groupedByTax.find(i => i.type == item.tax_rate);
            var amount =  Number(parseFloat(item.price_netto * item.qty_sto).toFixed(2)) * -1;
            if(item.discountable == 1 && (this.order.discount != null || this.order.discount > 0)){
                // Addiere nun noch den Rabatt, da ja nicht der original Preis storniert werden darf
                amount = Number(parseFloat(amount).toFixed(2)) + this.getDiscountPerItem(item) * item.qty_sto;
            }
            if(tax == undefined)
            {
                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                var entry = {
                    type: item.tax_rate,
                    amount: Number(parseFloat(amount).toFixed(2)),
                };

                groupedByTax.push(entry);
            }
            else
            {
                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(amount).toFixed(2))).toFixed(2));
            }
        }, this);

        this.stornos_additional.forEach(function(item) {
            var tax = groupedByTax.find(i => i.type == item.tax_rate);
            var amount =  0;
            if(item.type == 'normal')
            {
              amount =  Number(parseFloat(item.price * item.quantity).toFixed(2));
            }

            if(item.type == 'deposit')
            {
              amount = Number(parseFloat((item.price * item.quantity) * -1).toFixed(2));
            }

            if(tax == undefined)
            {
                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                var entry = {
                    type: item.tax_rate,
                    amount: Number(parseFloat(amount).toFixed(2)),
                };

                groupedByTax.push(entry);
            }
            else
            {
                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(amount).toFixed(2))).toFixed(2));
            }
        }, this);

        //Hole noch die Versandkosten und addiere 
        if(this.order.invoice_shipping_net > 0 && this.stornoShipping == true){
          var taxForShipping = groupedByTax.findIndex(item => item.type == parseFloat(this.order.invoice_shipping_tax_rate).toFixed(2));
          if(taxForShipping == -1){
            //ToDo Füge Steuersatz hinzu.
            var entry = {
                type: parseFloat(this.order.invoice_shipping_tax_rate).toFixed(2),
                amount: Number(parseFloat(this.order.invoice_shipping_net).toFixed(2)) * -1
            };

            groupedByTax.push(entry);
          }
          else{
            groupedByTax[taxForShipping].amount -= Number(parseFloat(this.order.invoice_shipping_net).toFixed(2));
          }
        }
        var taxes = [];
        groupedByTax.forEach((item) => {
            var tax = {};

            if(item.amount > 0)
            {
                tax = {
                    'tax':  item.type,
                    'regularAmount': item.amount,
                    'regularTax': Number(parseFloat(item.amount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                };
            }
            else{
                tax = {
                    'tax':  item.type,
                    'regularAmount': item.amount,
                    'regularTax': Number(parseFloat(item.amount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                };
            }
            taxes.push(tax)
        },this);
        return taxes;
    },


    calculateTaxesBrutto(){
        var groupedByTax = [];

        this.stornos.forEach(function(item) {
            var tax = groupedByTax.find(i => i.type == item.tax_rate);
            var amount =  Number(parseFloat(item.price * item.qty_sto).toFixed(2));
            // console.log(amount);
            if(item.discountable == 1 && (this.order.discount != null || this.order.discount > 0)){
                // Addiere nun noch den Rabatt, da ja nicht der original Preis storniert werden darf
                amount = Number(parseFloat(amount).toFixed(2)) - this.getDiscountPerItem(item) * item.qty_sto;
            }
            if(tax == undefined)
            {
                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                var entry = {
                    type: item.tax_rate,
                    amount: Number(parseFloat(amount).toFixed(2)),
                };

                groupedByTax.push(entry);
            }
            else
            {
                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(amount).toFixed(2))).toFixed(2));
            }
        }, this);

        this.stornos_additional.forEach(function(item) {
            var tax = groupedByTax.find(i => i.type == item.tax_rate);
            var amount =  0;
            if(item.type == 'normal')
            {
              amount =  Number(parseFloat(item.price * item.quantity).toFixed(2));
            }

            if(item.type == 'deposit')
            {
              amount = Number(parseFloat((item.price * item.quantity)).toFixed(2));
            }
            if(tax == undefined)
            {
                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                var entry = {
                    type: item.tax_rate,
                    amount: Number(parseFloat(amount).toFixed(2)),
                };

                groupedByTax.push(entry);
            }
            else
            {
                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(amount).toFixed(2))).toFixed(2));
            }
        }, this);
        
         //Hole noch die Versandkosten und addiere 
        if(this.order.invoice_shipping > 0 && this.stornoShipping == true){
          var taxForShipping = groupedByTax.findIndex(item => item.type == parseFloat(this.order.invoice_shipping_tax_rate).toFixed(2));
          if(taxForShipping == -1){
            //ToDo Füge Steuersatz hinzu.
            var entry = {
                type: parseFloat(this.order.invoice_shipping_tax_rate).toFixed(2),
                amount: Number(parseFloat(this.order.invoice_shipping).toFixed(2)) * -1
            };

            groupedByTax.push(entry);
          }
          else{
            groupedByTax[taxForShipping].amount += Number(parseFloat(this.order.invoice_shipping).toFixed(2));
          }
        }
        var taxes = [];
        groupedByTax.forEach((item) => {
            var tax = {};

            if(item.amount > 0)
            {
                tax = {
                    'tax':  item.type,
                    'regularAmount': item.amount,
                    'regularTax': Number(parseFloat((item.amount / (100 + Number(parseFloat(item.type).toFixed(2)))) * Number(parseFloat(item.type).toFixed(2))).toFixed(2))
                };
            }
            else{
                tax = {
                    'tax':  item.type,
                    'regularAmount': item.amount,
                    'regularTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type).toFixed(2)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                };
            }
            taxes.push(tax)
        },this);
        return taxes;
    },

    generateTotalStornoBrutto(){
      var storno_total = 0;

      if(this.order.tax == 0){
        //Hole Netto und addiere steuern
        storno_total = this.generateTotalStornoNetto();
        //ToDo: Addiere hier die Steuern.
        this.calculateTaxesNetto().forEach(item => {
            storno_total += item.regularTax;
        });
      }
      else{
        this.stornos.forEach(function (storno){
          // this.storno_total -= storno.qty_sto * storno.price;
          storno_total -= storno.qty_sto * (Number(parseFloat(storno.price).toFixed(2)));
          if(storno.discountable == 1 && (this.order.discount != null || this.order.discount > 0)){
              // Addiere nun noch den Rabatt, da ja nicht der original Preis storniert werden darf
              storno_total += this.getDiscountPerItem(storno) * storno.qty_sto;
          }
        },this);

        this.stornos_additional.forEach(function (storno){
          //Prüfe ob es ein normaler Artikel ist.
          if(storno.type == 'normal')
          {
            // Es ist ein normaler Artikel
            storno_total -= storno.quantity * (Number(parseFloat(storno.price).toFixed(2)));
          }

          if(storno.type == 'deposit')
          {
              //Kunde ist Firmenkunde. Pfand ist plus Mwst
              storno_total -= storno.quantity * (storno.price);
          }
          
        },this);

        if(this.stornoShipping == true)
        {
          storno_total -= this.order.invoice_shipping;
        }
      }

      return storno_total;
    },


    generateTotalStornoNetto(){
      var storno_total_netto = 0;

      if(this.order.tax == 0){
        //Kunde ist Netto Kunde. Berechne alles auf Netto Basis.
        this.stornos.forEach(function (storno){
          // this.storno_total -= storno.qty_sto * storno.price;
          storno_total_netto -= storno.qty_sto * (Number(parseFloat(storno.price_netto).toFixed(2)));
          if(storno.discountable == 1 && (this.order.discount != null || this.order.discount > 0)){
              // Addiere nun noch den Rabatt, da ja nicht der original Preis storniert werden darf
              storno_total_netto += this.getDiscountPerItem(storno) * storno.qty_sto;
          }
        },this);

        this.stornos_additional.forEach(function (storno){
          //Prüfe ob es ein normaler Artikel ist.
          if(storno.type == 'normal')
          {
            // Es ist ein normaler Artikel
            storno_total_netto += storno.quantity * (Number(parseFloat(storno.price).toFixed(2)));
          }

          if(storno.type == 'deposit')
          {
              //Kunde ist Firmenkunde. Pfand ist plus Mwst
              storno_total_netto += storno.quantity * (storno.price *-1);
          }
          
        },this);

        if(this.stornoShipping == true)
        {
          storno_total_netto -= this.order.invoice_shipping_net;
        }
      }
      else{
        //ToDo: Kunde ist Brutto Kunde. Nimm dir das Brutto und ziehe die Steuern ab.

        storno_total_netto = this.generateTotalStornoBrutto();
        // console.log('Netto' + storno_total_netto);
        this.calculateTaxesBrutto().forEach(item => {
            storno_total_netto += item.regularTax;
            // console.log('Steuer:' + item.regularTax);
        });
        //ToDo: Subtrahiere nun die Steuer....
      }
      return storno_total_netto;
    },

    generateTotalStorno()
    {
      this.storno_total = 0;
      this.storno_total_netto = 0;

      // if(this.order.tax == 0){
      //   this.stornos.forEach(function (storno){
      //     // this.storno_total -= storno.qty_sto * storno.price;
      //     this.storno_total_netto += storno.qty_sto * (Number(parseFloat(storno.price_netto).toFixed(2)) * -1);
      //     if(storno.discountable == 1 && (this.order.discount != null || this.order.discount > 0)){
      //         // Addiere nun noch den Rabatt, da ja nicht der original Preis storniert werden darf
      //         this.storno_total_netto += this.getDiscountPerItem(storno) * storno.qty_sto;
      //     }
      //     // this.storno_total_netto -= storno.qty_sto * ((parseFloat(storno.price) / (100 + parseFloat(storno.tax_rate))) * 100)

      //   },this);
      // }

      

      // this.stornos_additional.forEach(function (storno){
      //   //Prüfe ob es ein normaler Artikel ist.
      //   if(storno.type == 'normal')
      //   {
      //     // Es ist ein normaler Artikel
      //     this.storno_total += storno.quantity * (storno.price * -1);
      //     this.storno_total_netto += storno.quantity * (Number(parseFloat((parseFloat(storno.price) / (100 + parseFloat(storno.tax_rate))) * 100).toFixed(2)) * -1);
      //     // this.storno_total_netto += storno.quantity * (((parseFloat(storno.price) * -1) / (100 + parseFloat(storno.tax_rate))) * 100)
      //   }

      //   if(storno.type == 'deposit')
      //   {
      //     //Prüfe ob es sich um einen Firmen oder Privatkunden handelt.
      //     if(this.order.customer.group.tax == 1)
      //     {
      //       //Kunde ist ein Privatkunde
      //       this.storno_total += storno.quantity * (storno.price * -1);
      //       this.storno_total_netto += storno.quantity * (((parseFloat(storno.price) * -1) / (100 + parseFloat(storno.tax_rate))) * 100)
      //     }

      //     if(this.order.customer.group.tax == 0)
      //     {
      //       //Kunde ist Firmenkunde. Pfand ist plus Mwst
      //       this.storno_total += storno.quantity * (((parseFloat(storno.price) / 100) * -1) * (100 + parseFloat(storno.tax_rate)));
      //       this.storno_total_netto += storno.quantity * (storno.price * -1);
      //     }
      //   }
        
      // },this);

      // if(this.stornoShipping == true)
      // {
      //   this.storno_total -= this.order.invoice_shipping;
      //   this.storno_total_netto -= this.order.invoice_shipping_net;
      // }
    },

    // stornoItem(id){
    //   var article = this.order.items.find(data => data.id == id);
    //   // article.qty_sto = 0;
    //   this.stornos.push({
    //     articleordernumber: article.articleordernumber,
    //     name: article.name,
    //     quantity: article.quantity,
    //     canceled: article.canceled,
    //     tax_rate: article.tax_rate,
    //     price: article.price,
    //     article_id: article.article_id,
    //     article_detail_id: article.article_detail_id,
    //     tax_id: article.tax_id,
    //   });
    // },

    handleFullStorno(){
        this.storno_final = {
          order_id: '',
          comment: '',
          status_id: '',
          invoice_amount: '',
          invoice_amount_net: '',
          items: [],
        },
        this.stornos = [];
        this.order.items.forEach(function (item) {
            var article = this.order.items.find(data => data.id == item.id);
            // if(article.quantity - article.canceled > 0)
            // {
              this.stornos.push({
                articleordernumber: article.articleordernumber,
                name: article.name,
                quantity: article.quantity,
                canceled: article.canceled,
                tax_rate: article.tax_rate,
                price: article.price,
                price_netto: Number(parseFloat((Number(parseFloat(article.price).toFixed(2)) / (100 + Number(parseFloat(article.tax_rate).toFixed(2)))) * 100).toFixed(2)),
                qty_sto: article.quantity,
                tax_id: article.tax_id,
                article_id: article.article_id,
                article_detail_id: article.article_detail_id,
                order_item_id: article.id,
                voucher_id: article.voucher_id,
                discountable: article.discountable,
              });
            // }
        }, this);
        this.stornoShipping = true;
      this.$swal({
          title: "Möchtest du die Bestellung wirklich stornieren?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: `Ja, stornieren!`,
          denyButtonText: `Abbrechen`,
      })
      .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
              
              this.doCorrection();
          }
      });
    },

    getDiscountPerItem(storno){
      // <span v-if="storno.discountable == 1 && (order.discount != null || order.discount > 0)" style="color:red;">(+{{ Number(parseFloat(storno.price / 100 * getDiscountPercent() * storno.qty_sto).toFixed(2)) | toCurrency }})</span>
      
      if(storno.discountable == 1 && (this.order.discount != null || this.order.discount > 0)){
        if(this.order.tax == 1){
           return Number(parseFloat(storno.price / 100 * this.getDiscountPercent()).toFixed(2))
        }
        else{
            return Number(parseFloat(storno.price_netto / 100 * this.getDiscountPercent()).toFixed(2))
        }
        
      }
      else{
        return 0;
      }
    },

    getDiscountPercent(){
      return Number(parseFloat((100 / this.getDiscountableTotal()) * parseFloat(this.order.discount)).toFixed(2));
    },


    getDiscountableTotal(){
      var sum = 0;
        this.stornos.forEach(function (item) {
          if(item.discountable == true || item.discountable == 1){
              if(this.order.tax == 1){
                //Brutto
                sum += parseFloat(item.price) * item.quantity
              }
              if(this.order.tax == 0){
                //Brutto
                sum += parseFloat(item.price_netto) * item.quantity
              }
          }
        },this);
      return Number(parseFloat(sum).toFixed(2));
    },


    handleModal(){
      this.stornos = [];
      this.order.items.forEach(function (item) {
          var article = this.order.items.find(data => data.id == item.id);
          // if(article.quantity - article.canceled > 0)
          // {
            this.stornos.push({
              articleordernumber: article.articleordernumber,
              name: article.name,
              quantity: article.quantity,
              canceled: article.canceled,
              tax_rate: article.tax_rate,
              price: article.price,
              price_netto: Number(parseFloat((parseFloat(article.price) / (100 + parseFloat(article.tax_rate))) * 100).toFixed(2)),
              qty_sto: 0,
              tax_id: article.tax_id,
              article_id: article.article_id,
              article_detail_id: article.article_detail_id,
              order_item_id: article.id,
              voucher_id: article.voucher_id,
              voucher: article.voucher,
              discountable: article.discountable,
              type: 'original',
            });
          // }
      }, this);
      this.$bvModal.show("stornoModal");
    },

    addArticle(index){
      var article = this.articles.data[index];
      this.order.items.push({
        'articleordernumber': article.details.ordernumber,
        'name': article.name,
        'quantity': 1,
        'price': article.price,
        'tax_rate': article.tax_rate,
        'article_id': article.id,
        'article_detail_id': article.details.id,
        'order_id': this.order.id,
        'ordernumber': this.order.ordernumber,
        'id': null,
      });
      this.$bvModal.hide("articleModal");
    },

    addArticleModal(){
      this.$bvModal.show("articleModal");
    },

    changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        getAllArticles(page = 1) {
            this.$Progress.start();
            this.axios
                .get("/articles", {
                    params: {
                        page,
                        search: this.search.length >= 4 ? this.search : '',
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers")
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

    addDepositReturn(){
      this.order.items.push({
        'articleordernumber': 'Pfand',
        'name': 'Pfandrückgabe',
        'quantity': 1,
        'price': 0,
        'tax_rate': '19.00',
        'article_id': 0,
        'article_detail_id': 0,
        'order_id': this.order.id,
        'ordernumber': this.order.ordernumber,
        'id': null,
      });
    },


    removeItem(index){
      this.$delete(this.order.items, index)
    },

    addPosition(){
      this.order.items.push({
        'articleordernumber': 'Freie Position',
        'name': 'Gib einen Namen ein',
        'quantity': 1,
        'price': 0,
        'tax_rate': '7.00',
        'article_id': 0,
        'article_detail_id': 0,
        'order_id': this.order.id,
        'ordernumber': this.order.ordernumber,
        'id': null,
      });
    },
    groupBy(array, key){
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },

    saveOrder(){
      // 1) Ich muss die MwSt Satz für den Versand neu berechnen.
      var taxes = this.groupBy(this.order.items, 'tax_rate');
      var data =  [];
      for (const [key, value] of Object.entries(taxes)) {

        let newObject = {
          rate: key,
          value: (value.reduce((acc, item) => acc + (item.price * item.quantity), 0))
        }
        data.push(newObject);
      }

      var tax_rate = data.reduce((max, obj) => (max.value > obj.value) ? max : obj);
      var invoice_shipping_tax_rate = tax_rate.rate;


      // 2) Berechne die Netto Versandkosten erneut.
      var invoice_shipping_net = ((this.order.invoice_shipping / (100 + parseFloat(invoice_shipping_tax_rate))) * 100); 

      // 3) Berechene den Grundbetrag der Rechnung. Brutto sowie Netto

      var invoice_amount = (this.order.items.reduce((acc, item) => acc + (item.price * item.quantity), 0)) + this.order.invoice_shipping;
      var invoice_amount_net = (this.order.items.reduce((acc, item) => acc + (((item.price * item.quantity) / (100 + parseFloat(item.tax_rate)) * 100)), 0)) + invoice_shipping_net;
      
      this.$Progress.start();
      this.axios
          .put("/orders/" + this.order.id, {
            'invoice_shipping_tax_rate': parseFloat(invoice_shipping_tax_rate).toFixed(2),
            'invoice_shipping_net': invoice_shipping_net,
            'invoice_amount': invoice_amount,
            'invoice_amount_net': invoice_amount_net,
            'items': this.order.items,
          })
          .then(() => {
              // this.billingStates = response.data.data;
              this.$parent.getOrder();
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    },

    closeEdit(){
      this.selectedRow = {};
      this.selectedCell = '';
    },

    handleEditRow(index) {
        this.selectedRow = {
          [index]: !this.selectedRow[index]
        }
    },

    handleEditCell(index, name) {
        this.selectedCell = name;
        this.selectedRow = {
          [index]: !this.selectedRow[index]
        }
    },

    loadTaxes(){
      this.$Progress.start();
      this.axios
          .get("/taxes")
          .then((response) => {
              this.taxes = response.data.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    }
  },

  mounted() {
    this.loadTaxes();
    this.getAllArticles();
    this.getProducers();
    if(this.order.chargeId != null)
    {
      this.refund.mode = 'amazon';
    }

    else if(this.order.paypal_order_id != null){
      this.refund.mode = 'paypal';
    }
    else if(this.order.stripe_pi != null){
      this.refund.mode = 'card';
    }
    else if(this.order.ingenico_paymentId != null){
      this.refund.mode = 'card_ingenico';
    }
    else{
      this.refund.mode = 'bank';
    }

  }
}
</script>

<style>

</style>